import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import styles from "../styles/landing.module.css"
import "../styles/global.css"

function Landing() {
  return (
    <div id="home" className="h-100">
      <Container className={styles.landing} fluid>
        <Row className="align-items-center h-100">
          <Col
            className="first-trenner"
            style={{ backgroundColor: "#00869B" }}
            sm={{ span: 6, offset: 2 }}
          >
            <h2 className="font-weight-bold display-3 text-white">
              Herzlich Willkommen
            </h2>
            <h1 className=" display-4 text-white">
              in der podologischen Praxis Reschke
            </h1>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Landing
