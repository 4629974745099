import React from "react"
import { Container, Row, Col } from "react-bootstrap"

function Preise() {
  return (
    <div id="preisliste">
      <Container fluid style={{ padding: "0px" }}>
        <Row noGutters>
          <Col xl={5} style={{ backgroundColor: "#00869B", height: "1200px" }}>
            <div className="mb-5 mt-5">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <h4>Podologie</h4>
                  <br />
                  Persönliche Beratung <span>23,00 €</span>
                </li>
                <li class="list-group-item">
                  Podologische Komplexbehandlung <span>64,00 €</span>
                </li>
                <li class="list-group-item">
                  Podologische Komplexbehandlung mit Fußbad <span>70,00 €</span>
                </li>
                <li class="list-group-item">
                  Podologische Komplexbehandlung mit Fußbad und Peeling
                  <span>76,00 €</span>
                </li>
                <li class="list-group-item">
                  Podologische Komplexbehandlung Hausbesuch{" "}
                  <span>104,00 €</span>
                </li>
                <li class="list-group-item">
                  Podologische Komplexbehandlung 5er - Abo <span>272,00 €</span>
                </li>
                <li class="list-group-item">
                  Nagelbearbeitung <span>36 €</span>
                </li>
                <li class="list-group-item">
                  Eingewachsener Fußnagel <span>30,00 €</span>
                </li>
                <li class="list-group-item">
                  Nagelaufbau <span>ab 41,00 €</span>
                </li>
                <li class="list-group-item">
                  Hornhautabtragung je nach Zeitaufwand <span>ab 30,00 €</span>
                </li>
                <li class="list-group-item">
                  Hühneraugen- Entfernung je nach Zeitaufwand
                  <span>ab 30,00 €</span>
                </li>
                <li class="list-group-item">
                  Warzenbehandlung je nach Zeitaufwand <span>ab 19,00 €</span>
                </li>
              </ul>
            </div>
            <div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <h4>Spangentherapie</h4>
                  Bei eingewachsenen Fußnägeln
                </li>
                <li class="list-group-item">
                  Orthonyxiespange nach „ Ross-Fraser“ Förderung durch
                  Krankenkassen möglich
                  <span>276,00 €</span>
                </li>
                <li class="list-group-item">
                  Naspan <span>69,00 €</span>
                </li>
                <li class="list-group-item">
                  Onyfix <span>64,00 €</span>
                </li>
              </ul>
            </div>
          </Col>
          <Col
            xl={7}
            style={{ backgroundColor: "#00A5BF", height: "1200px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="" style={{ marginLeft: "30px" }}>
              <h3 className="text-white font-weight-light info-text">
                Wir bitten Sie, unsere Podologiepraxis bei Terminabsagen 24
                Stunden vorab zu informieren, damit unsere Podologin den Termin
                wieder belegen kann. Ansonsten müssen wir die kompletten
                Behandlungskosten in Rechnung stellen! Vielen dank für Ihr
                Verständnis.
              </h3>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Preise
